import { Link, useNavigate } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faBackward, faFolder } from "@fortawesome/free-solid-svg-icons";
import { CustomModalPage } from '../../../components/Modal';
import { cnpj } from "cpf-cnpj-validator";

import './styles.css';
import '../Client/styles.css'
import lang from './lang'; 

import Sidebar from '../../../components/Sidebar';
import FlagSelector from '../../../components/FlagSelector';
import FileUpload from '../../../components/FileUpload';
import { deleteJsonData, getStoredJson, saveJsonData, useInitialCheck } from '../../../utils';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { addDataBaseRow, editDatabaseByCondition, editDatabaseRowsByColumn, genUUID, getDataBaseCondition, getImagePaths, sendImageDatabase} from '../../../utils/database';
import CustomTextField from '../../../components/CustomTextField';
import AsyncButton from '../../../components/AsyncButton';


export function CompanyPage() {
  const {globalOptions, setGlobalOptions} = useGlobalContext()
  const currLang = globalOptions.language

  const [edit, setEdit] = useState(false)
  const [showMsg, setShowMsg] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<any>({})
  const [imageData, setImageData] = useState<any>([])
  const [loading, setLoading] = useState(false)
  
  const navigate = useNavigate();
  useInitialCheck(initialCheck, [window.location.href])

  async function initialCheck(){
    const currentUrl = window.location.href
    setEdit(currentUrl.indexOf('?edit') != -1)

    if(currentUrl.indexOf('?edit') == -1)
      return null

    const editData = getStoredJson('edit')
    setImageData(await getImagePaths(editData.img_path))

    setSelectedCompany(editData)
  }

  function handleNameChange(text: string){
    setSelectedCompany({...selectedCompany, name: text})
  }

  function handleAreaChange(text: string){
    setSelectedCompany({...selectedCompany, area: text})
  }

  function handleDescriptionChange(text: string){
    setSelectedCompany({...selectedCompany, description: text})
  }

  function handleCNPJChange(text: any){
    const rawValue = text.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (rawValue.length > 14) 
      return

    let maskedValue = ''

    for (let i = 0; i < rawValue.length; i++){
      if (i === 2 || i === 5)
        maskedValue += '.'
      if (i === 8 )
        maskedValue += '/'
      if (i === 12 )
        maskedValue += '-'
      
      maskedValue += rawValue[i]
    }

    setShowMsg(false)
    setSelectedCompany({...selectedCompany, cnpj: maskedValue})
  }

  function handleManagement(){
    setGlobalOptions({...globalOptions, activeButton: 'Company'})
    navigate('/Managment')
  }

  async function handleEdit(){
    const edit = getStoredJson('edit')

    const newData = {
      name: selectedCompany.name,
      area: selectedCompany.area,
      cnpj: selectedCompany.cnpj,
      description: selectedCompany.description,
      img_path: `img/company/${selectedCompany.id}`,
    }

    let response = await editDatabaseByCondition('companies', 'cnpj', edit.cnpj, newData)

    if(response.status != 'success')
      return alert('erro')

    if(edit.cnpj != newData.cnpj){
      response = await editDatabaseRowsByColumn('plants', 'company', edit.cnpj, newData.cnpj)
      response = await editDatabaseRowsByColumn('processes', 'company', edit.cnpj, newData.cnpj)
      response = await editDatabaseRowsByColumn('equipments', 'company', edit.cnpj, newData.cnpj)
      response = await editDatabaseRowsByColumn('inspections', 'company', edit.cnpj, newData.cnpj)
    }

    for (let x=0; x<imageData.length; x++){
      const file = imageData[x]
      await sendImageDatabase(file, edit.id, 'company', x)
    }
    
    navigate(-1)
  }

  async function handleSubmit(){
    const rawValue = selectedCompany.cnpj.replace(/\D/g, '');

    if(!cnpj.isValid(rawValue))
      return setShowMsg(true)

    const uuid = genUUID()
    
    const newData = {
      id: uuid,
      name: selectedCompany.name,
      area: selectedCompany.area,
      cnpj: selectedCompany.cnpj,
      description: selectedCompany.description,
      last_modified_at: '',
      server_created_at: '',
      was_deleted: 0,
      img_path: `img/company/${uuid}`,
    }

    const checkUnique = await getDataBaseCondition('companies', 'cnpj', newData.cnpj)

    if(checkUnique.length > 0)
      return alert(lang.alreadyRegistered[currLang])

    const response = await addDataBaseRow('companies', newData)

    if(response.status != 'success')
      return alert(lang.addError[currLang])

    for (let x=0; x<imageData.length; x++){
      const file = imageData[x]
      await sendImageDatabase(file, uuid, 'company', x)
    }

    saveJsonData('insert', {company: newData.cnpj})
    setModalOpen(true)
  }

  function isFieldsEmpty(){
    const data = [
      selectedCompany?.name,
      selectedCompany?.cnpj,
      selectedCompany?.area,
      selectedCompany?.description,
    ]

    for(let value of data){
      if(!value)
        return true

      if(value.trim().length == 0)
        return true
    }

    return false
  }

  return (
    <div className="MainContainer" >
      <CustomModalPage
        title={lang.companyRegistered[currLang]}
        subtitle={lang.wantToRegisterPlant[currLang]}
        yesPage='/Plant?insert'
        noPage='/Home'
        yesText={lang.yes[currLang]}
				noText={lang.no[currLang]}
        modal={modalOpen}
      />

      <div className='SideBarCompensation'/>
      
      <div className="CentralBlock" style={{height: '100vh'}}>
        <FlagSelector/>
        <Sidebar activePage='Register'/>
        
        <div className="RegisterMainContainer">
          <div className="iconContainer" style={{width: '100%'}}>
              <div
                className='iconLeft' 
                style={{alignSelf: 'center', width: '7%'}}
                onClick={() => navigate(-1)}
                >
                
                <FontAwesomeIcon icon={faAngleLeft} title="Empresas já cadastradas"/> 
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', width: '85%'}}>
                <p className='clientText'>
                  {edit ? lang.editCompany[currLang] : lang.registerCompany[currLang]}
                </p>
              
                <div className='folderContainer' onClick={handleManagement}>
                  <FontAwesomeIcon icon={faFolder} title="Empresas já cadastradas"/>        
                </div>
              </div>
          </div>

          <div style={{width: '85%'}}>
            <text className='equipmentText2'>{lang.companyInfo[currLang]}</text>
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{width: '85%'}}>
            <CustomTextField 
              placeholder={lang.name[currLang]}
              value={selectedCompany.name} 
              setValue={handleNameChange}
            />
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{width: '85%'}}>
            <CustomTextField 
              placeholder='CNPJ' 
              value={selectedCompany.cnpj} 
              setValue={handleCNPJChange}
            />
            
            {showMsg === true && <p style={{ color: 'red', marginBottom: '-0.5rem', paddingLeft: '0.5rem' }}>{lang.invalidCNPJ[currLang]}</p>}
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{width: '85%'}}>
            <CustomTextField
              placeholder={lang.occupationArea[currLang]}
              value={selectedCompany.area} 
              setValue={handleAreaChange}
            />
          </div>

          <div style={{padding: '.6rem'}}/>

          <div style={{width: '85%'}}>
            <CustomTextField
              placeholder={lang.description[currLang]}
              value={selectedCompany.description} 
              setValue={handleDescriptionChange}
            />
          </div>
          
          <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '85%'}}>
            <FileUpload
              title={lang.companyLogo[currLang]}
              setImg={setImageData}
              img={imageData.length > 0 ? imageData[0] : null}
              multiple={false}
            />
          </div>

          <AsyncButton
            active={loading || isFieldsEmpty()}
            text={edit ? lang.edit[currLang] : lang.register[currLang]}
            className='button-company'
            onClick={
              edit 
              ? 
              async () => {setLoading(true); await handleEdit(); setLoading(false)}
              :
              async () => {setLoading(true); await handleSubmit(); setLoading(false)}
            }
            loading={loading}
          />

        </div>
      </div>
    </div>
  );
}

