import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

type TagsType = {
    options: any[],
    value: any,
    setValue: (value: any) => void,
    placeholder: string,
    labelKey?: string,
    callbackKey?: any
}

const sxOptions = {
    width: '100%',
    height: '3.5rem',
    display: 'flex',
    alignItems: 'center',
    borderWidth: '15px',
    backgroundColor: '#fff',

    '.MuiAutocomplete-tag': {
        height: '1.5rem', // Diminui a altura do chip
        fontSize: '12px', // Diminui o tamanho da fonte dentro do chip
        '.MuiChip-deleteIcon': {
            height: '.8rem'
        },
        marginTop: '8px'
    },

    borderRadius: '.6rem',
    '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, 1px) scale(0.8)',
      fontSize: '11px', 
    },
    '.MuiAutocomplete-listbox': {
      fontSize: '12px', // Ajusta o tamanho da fonte dos itens do dropdown    
    },
};

const sxTextFieldOptions = {
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: '.6rem',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '.MuiInputBase-input': {
      fontSize: '12px', // Ajusta o tamanho da fonte do texto dentro do TextField
    },
    '.MuiInputLabel-outlined': {
      fontSize: '13px', // Ajusta o tamanho da fonte da label quando não está "shrinked"
      opacity: .5,
      
    },
};


export default function Tags({options, value, setValue, placeholder, labelKey = 'name', callbackKey = null}: TagsType) {
    if (callbackKey) 
        options = [{ [labelKey]: callbackKey, key: 'default' }, ...options]

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
    };
    const safeValue = value || [];

    // Filtra as opções para remover as já selecionadas
    const filteredOptions = options.filter(option => 
        !safeValue.some((selected: { [x: string]: any; }) => selected[labelKey] === option[labelKey])
    );

    return (
        <div style={{width: '100%'}}>
            <Autocomplete
                multiple
                options={filteredOptions}
                getOptionLabel={(option) => option[labelKey]}
                value={value}
                onChange={handleChange}
                sx={sxOptions}
                noOptionsText='Não há dados cadastrados'
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={placeholder}
                        sx={sxTextFieldOptions}
                    />
                )}
            />
        </div>
    );
}
