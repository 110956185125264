const lang: any = {
    calendar: {
        PT: "Calendário",
        US: "Calendar",
        ES: "Calendario"
    },
    showAll: {
        PT: "Exibir Tudo",
        US: "Show All",
        ES: "Mostrar Todo"
    },
        
}

export default lang;
