const lang: any = {
    reports: {
        'PT': 'Relatórios',
        'US': 'Reports',
        'ES': 'Informes'
    },

    corrosionRates: {
        'PT': 'Índices de Corrosão',
        'US': 'Corrosion Rates',
        'ES': 'Índices de Corrosión'
    },

    inspectionBoard: {
        'PT': 'Quadro de Inspeções',
        'US': 'Inspection Board',
        'ES': 'Cuadro de Inspecciones'
    }
}

export default lang
