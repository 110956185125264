import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FlagSelector from '../../../components/FlagSelector'
import Sidebar from '../../../components/Sidebar'
import { useGlobalContext } from '../../../contexts/GlobalContext'
import lang from './lang';
import './styles.css'
import { faAngleLeft, faChartLine, faDashboard, faLineChart, faPieChart } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'


export function SelectReportPage(){
    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate()

    return (
        <div className='MainContainer'>
            <FlagSelector/>
            <Sidebar/>
            <div className='SideBarCompensation'/>

            <div className='CentralBlock'>
                <div className='SelectReportContainer'>
                    <div className='HeaderContainer'>
                        <FontAwesomeIcon 
                            icon={faAngleLeft}
                            color='white'
                            fontSize='1.5rem'
                            cursor='pointer'
                            onClick={() => navigate(-1)}
                        />

                        <div style={{width: '2%'}}/>

                        <div className='TextStyle'>{lang.reports[currLang]}</div>
                    </div>

                    <div style={{flex: .05}}/>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: .80}}>
                        <div className='MainBoxesContainer'>
                            <div className='MainBox' onClick={() => navigate('/Corrosion')}>
                                <FontAwesomeIcon icon={faPieChart} fontSize='5rem' className='iconStyle'/>
                                <div className='TextStyle'>{lang.corrosionRates[currLang]}</div>
                            </div>

                            <div className='MainBox' style={{cursor: 'not-allowed', opacity: .6}}>
                                <FontAwesomeIcon icon={faLineChart} fontSize='5rem' className='iconStyle'/>
                                <div className='TextStyle'>{lang.inspectionBoard[currLang]}</div>
                            </div>
                        </div>

                        <div className='MainBoxesContainer'>
                            <div className='MainBox' style={{cursor: 'not-allowed', opacity: .6}}>
                                <FontAwesomeIcon icon={faDashboard} fontSize='5rem' className='iconStyle'/>
                                <div className='TextStyle'>{lang.corrosionRates[currLang]}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}