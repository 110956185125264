import { Link, Navigate, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCamera, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './styles.css';


import Sidebar from '../../../components/Sidebar';
import { useEffect, useRef, useState } from 'react';
import { getStoredJson, saveJsonData, useInitialCheck } from '../../../utils';
import { getDataBaseCondition, getDatabaseRows, getImagePaths } from '../../../utils/database';
import FlagSelector from '../../../components/FlagSelector';
import CustomDropBox from '../../../components/CustomDropBox';
import CustomTextField from '../../../components/CustomTextField';
import lang from './lang';
import { useGlobalContext } from '../../../contexts/GlobalContext';


export function SearchPage() {
    const [companies, setCompanies]  = useState<any>([]);
    const [plants, setPlants]  = useState<any>([]);
    const [process, setProcess]  = useState<any>([]);
    
    const [searchText, setSearchText] = useState<string>('')
    const [selectedCompany, setSelectedCompany] = useState<any>({})
    const [selectedPlant, setSelectedPlant] = useState<any>({})
    const [selectedProcess, setSelectedProcess] = useState<any>({})
    const [isHovered, setIsHovered] = useState(false);
    const [hoverId, setHoverId] = useState(0)

    const [numItemsToShow, setNumItemsToShow] = useState(4);

    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate()
    const user = getStoredJson('user')
    
    useInitialCheck(importCompanies)
    useInitialCheck(importPlants, [selectedCompany])
    useInitialCheck(importProcesses, [selectedPlant])

    useEffect(() => {
        const handleResize = () => {
          const width = window.innerWidth
          
          if (width < 1000)
            setNumItemsToShow(1)
          else if(width > 1000 && width < 1250)
            setNumItemsToShow(2)
          else if (width > 1250 && width < 1700)
            setNumItemsToShow(3)
          else
            setNumItemsToShow(4)
        }
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const handleLimparCampo = () => {
        setSearchText('')
        setSelectedCompany({})
        setSelectedPlant({})
        setSelectedProcess({})
        setIsHovered(false)
    }

    async function importCompanies(){
        let response = []

		if(user.company == '123456789/0001')
            response = await getDatabaseRows('companies')
		else
            response = await getDataBaseCondition('companies', 'cnpj', user.company)


        for(let x=0; x<response.length; x++){
            const company = response[x]
            const images  = await getImagePaths(company.img_path)
            
            response[x].companyImage = images.length > 0 ? images[0] : null
        }

        setCompanies(response)
    }

    async function importPlants(){
        const response = await getDataBaseCondition('plants', 'company', selectedCompany.cnpj) 
        setPlants(response)
    }

    async function importProcesses(){  
        const response = await getDataBaseCondition('processes', 'plant', selectedPlant.plant_id)
        //alert(JSON.stringify(response))
        setProcess(response)
    }

    function handleCompanyChange(item: any){
        setSelectedCompany(item)
        setSearchText(item.name)
    }

    function ImageBlock({item, index}: any){
        if(!item.companyImage)
            return (
                <div 
                    className='imgWrapper_Search' 
                    style={{paddingRight: (numItemsToShow == 2) ? '2rem' : '1rem'}}
                >
                    <div className='img_Search' 
                         style={{border: (selectedCompany === item) ? '3px solid #F36934' : '', opacity: (isHovered && index === hoverId) ? '1' : isHovered ? '0.5' : '1' }} 
                         onClick={() => {handleCompanyChange(item); setHoverId(0)}} 
                         onMouseEnter={() => { setIsHovered(true); setHoverId(index)}}
                         onMouseLeave={() => setIsHovered(false)}
                    >
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <FontAwesomeIcon icon={faCamera} className="cameraIcon"/>
                        </div>
                    </div>

                    <div className='underText_Search'>{item.name}</div>
                </div>
            )

        return (
            <div 
                className='imgWrapper_Search' 
                style={{paddingRight: (numItemsToShow == 2) ? '2rem' : '1rem'}}
            >
                <img className="img_Search" 
                     src={item.companyImage} 
                     title={item.name} 
                     alt="" 
                     style={{border: (selectedCompany === item) ? '3px solid #F36934' : '', opacity: (isHovered && index === hoverId) ? '1' : isHovered ? '0.5' : '1' }} 
                     onClick={() => {handleCompanyChange(item); setHoverId(0)}} 
                     onMouseEnter={() => { setIsHovered(true); setHoverId(index) }}
                     onMouseLeave={() => setIsHovered(false)}
                />
                <div className='underText_Search'>{item.name}</div>
            </div>
        )
    }

    function leftListBlock(){
        return (
            <div className='inputSearchContainer'>
                <div className='inputContainer_Search'>
                    <FontAwesomeIcon icon={faSearch} className='searchIcon'/>
                    
                    <CustomTextField 
                        placeholder={lang.company[currLang]} 
                        value={searchText} 
                        setValue={setSearchText}
                    />
                </div>
            </div>
        )
    }

    function renderImageList(){
        let dataCompany = companies.filter((item: any) => item.name.toLowerCase().includes(searchText.toLowerCase()))
        dataCompany = dataCompany.slice(0, numItemsToShow)

        let jf = (numItemsToShow == 1) ? 'center' :
                 (dataCompany.length > 3) ? 'space-between' : 'flex-start'

        if(!selectedCompany.name)
            return(
                <div 
                    className={`searchImages_Explore`} 
                    style={{justifyContent: jf}} 
                    onClick={async () => await importPlants()}
                >
                    {dataCompany.map((company: any, index: any) => <ImageBlock index={index} item={company}/>)}
                </div>
            )

        if(dataCompany.length == 0)
            return (
                <div className={`searchImages_Explore`}/>
            )
        
        return (
            <div 
                className={`searchImages_Explore`} 
                style={{justifyContent: jf}} 
                onClick={async () => await importPlants()}>
                {dataCompany.map((company: any, index: any) => <ImageBlock index={index} item={company}/>)}
            </div>
        )
    }

    function handleNextPage(){ 
        let selected

        if(selectedCompany?.name?.trim() == '')
            selected = 'company'
        else if(selectedPlant?.name?.trim() == '')
            selected = 'plant'
        else if(selectedProcess?.name?.trim() == '')
            selected = 'process'

        saveJsonData('searchItems', {
            items: selected,
            company: selectedCompany.cnpj ? selectedCompany.cnpj : null,
            plant: selectedPlant.plant_id ? selectedPlant.plant_id : null,
            process: selectedProcess.process_id ? selectedProcess.process_id : null
        })
        
        navigate('/List')
    }

    async function setPlantValue (selectedOption: any) {
        if(selectedOption.key && selectedOption.key == 'default'){
            saveJsonData('insert', {company: selectedCompany.cnpj})
            return navigate('/Plant?insert')
        }

        setSelectedPlant(selectedOption)
        setSelectedProcess({})
    }

    function setProcessValue (selectedOption: any) {
        if(selectedOption.key && selectedOption.key == 'default'){
            saveJsonData('insert', {company: selectedCompany.cnpj, plant: selectedPlant.plant_id})
            return navigate('/Process?insert')
        }

        setSelectedProcess(selectedOption)
    }; 

    function renderPPBlock(){        
        return(
            <div className='middleSide_Explore'>
                <CustomDropBox
                    options={plants}
                    value={selectedPlant.name ? selectedPlant.name : ''}
                    setValue={setPlantValue}
                    placeholder={lang.plant[currLang]}
                    key='name'
                    callbackKey={lang.addNew[currLang]}
                /> 
                                
                <div style={{padding: '0.3rem'}}/>

                <CustomDropBox
                    options={process}
                    value={selectedProcess.name ? selectedProcess.name : ''}
                    setValue={setProcessValue}
                    placeholder={lang.process[currLang]}
                    key='name'
                    callbackKey={lang.addNew[currLang]}
                /> 
            </div>
        )
    }

    function getSearchName(){
        if(!selectedCompany.name)
            return lang.searchCompanies[currLang]

        if(!selectedPlant.name)
            return lang.searchPlants[currLang]

        if(!selectedProcess.name)
            return lang.searchProcesses[currLang]

        return lang.searchEquipments[currLang]
    }

  return (
    <div className="MainContainer">
        <Sidebar activePage='Search'/>
        <FlagSelector/>

        <div className='SideBarCompensation'/>

        <div className="CentralBlock">
            <div className="content-wrapper_Explore">
                <div className="upperSide_Explore">
                    <div className='text-wraper'>
                        <div className="title_Home">{lang.whichCompany[currLang]}</div>
                        <button className='hiperlink_Explore' onClick={handleLimparCampo}>{lang.clean[currLang]}</button>
                    </div>

                    {leftListBlock()}

                    <div style={{padding: '.5rem'}}/>

                    {renderImageList()}
                    
                    <div style={{padding: '.5rem'}}/>

                    {renderPPBlock()}
                </div>
                
                <div className='buttonWrapper_Explore'>
                    <button className='button_Explore' onClick={handleNextPage}>{getSearchName()}</button>
                </div>
            </div>
        </div>
    </div>
  )
}