import './styles.css'


const LoadingDiv = ({children, loading=false, className='', style={}, onClick=undefined}: any) => {
    if(loading)
        return (
            <div className={className} style={{...style, flex : 1}} onClick={onClick}>
                <div className="skeleton" style={{flex: 1}}>
                    <div className={"skeleton-text1"}/>
                    <div className={"skeleton-text2"}/>
                    <div className={"skeleton-text3"}/>
                    <div className={"skeleton-text2"}/>
                    <div className={"skeleton-text1"}/>
                    <div className={"skeleton-text3"}/>
                </div>
            </div>
        )

    return <div className={className} style={style}>{children}</div>
}


export default LoadingDiv
