const lang: any = {
    registerEquipment: {
        PT: 'Cadastrar Novo Equipamento',
        US: 'Register New Equipment',
        ES: 'Registrar Nuevo Equipo'
    },

    editEquipment: {
        PT: 'Editar Equipamento',
        US: 'Edit Equipment',
        ES: 'Editar Equipo'
    },

    equipmentInfo: {
        PT: 'INFORMAÇÕES DO EQUIPAMENTO',
        US: 'EQUIPMENT INFO',
        ES: 'INFORMACIÓN DEL EQUIPO'
    },
    equipmentCapture: {
        PT: 'INDIQUE O LOCAL DO EQUIPAMENTO NO MAPA',
        US: 'SHOW THE EQUIPMENT LOCATION ON THE MAP',
        ES: 'INDICAR LA UBICACIÓN DEL EQUIPO EN EL MAPA'
    },
    equipmentImage: {
        PT: 'IMAGEM CAPTURADA',
        US: 'IMAGE CAPTURED',
        ES: 'IMAGEN CAPTURADA'
    },
    name: {
        PT: 'Nome',
        US: 'Name',
        ES: 'Nombre'
    },

    equipmentType: {
        PT: 'Tipo de Equipamento',
        US: 'Equipment Type',
        ES: 'Tipo de Equipo'
    },

    company: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    plant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    process: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    documents: {
        PT: 'Documentos',
        US: 'Documents',
        ES: 'Documentos'
    },

    photos: {
        PT: 'Fotos',
        US: 'Photos',
        ES: 'Fotos'
    },

    next: {
        PT: 'Próximo',
        US: 'Next',
        ES: 'Siguiente'
    },

    addNew: {
        PT: 'Adicionar Novo',
        US: 'Add New',
        ES: 'Agregar Nuevo'
    },

    thecnicData: {
        PT: 'DADOS TÉCNICOS',
        US: 'TECHNICAL DATA',
        ES: 'DATOS TÉCNICOS'
    },

    register: {
        PT: 'Cadastrar',
        US: 'Register',
        ES: 'Registrar'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    formRegister: {
        PT: 'Formulário de cadastro',
        US: 'Registration form',
        ES: 'Formulario de registro'
    },

    formMessage: {
        PT: 'Insira um email e uma mensagem para serem enviados ou copie o link e envie diretamente',
        US: 'Enter an email and a message to be sent, or copy the link and send it directly',
        ES: 'Introduzca un correo electrónico y un mensaje para enviar, o copie el enlace y envíelo directamente'
    },

    emailMsg: {
        PT: "Prezado(a),\n\nPor favor, preencha o formulário disponível no link abaixo para fornecer informações necessárias sobre o ativo. Sua colaboração é importante para nós.\n\nAgradecemos sua atenção e colaboração.\n\nAtenciosamente,\nIntegrAtivos",
        US: "Dear [Sir/Madam],\n\nPlease fill out the form available at the link below to provide necessary information about the asset. Your cooperation is important to us.\n\nThank you for your attention and cooperation.\n\nSincerely,\nIntegrAtivos",
        ES: "Estimado(a),\n\nPor favor, complete el formulario disponible en el enlace a continuación para proporcionar la información necesaria sobre el activo. Su colaboración es importante para nosotros.\n\nAgradecemos su atención y colaboración.\n\nAtentamente,\nIntegrAtivos"
    },

    formFillingRequest: {
        PT: "Solicitação de Preenchimento de Formulário",
        US: "Form Completion Request",
        ES: "Solicitud de Cumplimiento de Formulario"
    },

    equipmentRegistered: {
        PT: "Equipamento Cadastrado",
        US: "Equipment Registered",
        ES: "Equipo Registrado"
    },
    
    wantToRequestInspection: {
        PT: "Deseja Solicitar uma Inspeção?",
        US: "Do You Want to Request an Inspection?",
        ES: "¿Desea Solicitar una Inspección?"
    },
    close: {
        PT: "Fechar",
        US: "Close",
        ES: "Cerrar"
    },
    
    send: {
        PT: "Enviar",
        US: "Send",
        ES: "Enviar"
    },
    message: {
        PT: "Mensagem",
        US: "Message",
        ES: "Mensaje"
    },
    
    email: {
        PT: "Email",
        US: "Email",
        ES: "Correo Electrónico"
    },
    invalidEmail: {
        PT: "Email Inválido!",
        US: "Invalid Email!",
        ES: "¡Correo Electrónico Inválido!"
    },
    selectAFloor: {
        PT: "Selecionar um Andar",
        US: "Select a Floor",
        ES: "Seleccionar un Piso"
    },
    captureMap: {
        PT: "Capturar Mapa",
        US: "Capture Map",
        ES: "Capturar Mapa"
    },
    yes: {
        PT: "Sim",
        US: "Yes",
        ES: "Sí"
    },
    
    no: {
        PT: "Não",
        US: "No",
        ES: "No"
    },

    auxiliaryEquipment: {
        PT: "EQUIPAMENTO AUXILIAR?",
        US: "AUXILIARY EQUIPMENT?",
        ES: "EQUIPO AUXILIAR?"
    },
    
    mainEquipment: {
        PT: "Equipamento Principal",
        US: "Main Equipment",
        ES: "Equipo Principal"
    },
    units: {
        PT: "Unidade",
        US: "Unit",
        ES: "Unidade"
    },
    
    
}

export default lang;
