import {createContext, useContext, useReducer, ReactNode} from 'react';


// Defina os tipos de estado e ação que você precisa

export type RegisterState = {
    client: {
        name: string;
        email: string;
        password: string;
        company: string;
        category: string;
    };
    company: {
        name: string;
        cnpj: string;
        area: string;
        description: string;
        companyImage: string;   // URL da imagem para a empresa
    };
    equipment: {
        name: string;
        company: string;
        location: string;
        plant: string;
        process: string;
        equipmentImage: string;   // URL da imagem para a empresa
    };
    inspector: {
        name: string;
        email: string;
        password: string;
        level: string;
        cpf: string;
    };
    plant: {
        name: string;
        company: string;
        location:  string;
        latitude: number;
        longitude: number;        
        plantImage: string;     // URL da imagem para a planta
    };
    process: {
        name: string;
        company: string;
        location: string;
        plant: string;
        processImage: string;   // URL da imagem para o processo
    };
    
    // ... adicione outros estados necessários
};

export type RegisterAction =
    | { type: 'SET_CLIENT'; payload: Partial<RegisterState['client']> }
    | { type: 'SET_COMPANY'; payload: Partial<RegisterState['company']> }
    | { type: 'SET_EQUIPMENT'; payload: Partial<RegisterState['equipment']> }
    | { type: 'SET_INSPECTOR'; payload: Partial<RegisterState['inspector']> }
    | { type: 'SET_PLANT'; payload: Partial<RegisterState['plant']> }
    | { type: 'SET_PROCESS'; payload: Partial<RegisterState['process']> }
    // ... adicione outras ações necessárias

    type RegisterContextType = {
        state: RegisterState;
        dispatch: (action: RegisterAction) => void;
        saveImageToLocalStorage: (key: string, imageFile: File) => void;
        loadImageFromLocalStorage: (key: string) => void;
    };
    

// Crie o contexto Register

const RegisterContext = createContext<RegisterContextType | undefined>(undefined);

// Provedor de Contexto Register

type RegisterProviderProps = {
    children: ReactNode;
};

const RegisterProvider: React.FC<RegisterProviderProps> = ({ children }) => {
    const initialState: RegisterState = {
        client: {
            name: '',
            email: '',
            password: '',
            company: '',
            category: '',
        },
        company: {
            name: '',
            cnpj: '',
            area: '',
            description: '',
            companyImage: ''
        },
        equipment: {
            name: '',
            company: '',
            location: '',
            plant: '', 
            process: '',
            equipmentImage:''
        },
        inspector: {
            name: '',
            email: '',
            password: '',
            level: '',
            cpf: ''
        },
        process: {
            name: '',
            company: '',
            location: '',
            plant: '',
            processImage: ''
            
        },
        plant: {
            name: '',
            company: '',
            location: '',
            latitude: 0,
            longitude: 0,
            plantImage: ''
            
        }
    };

    const registerReducer = (state: RegisterState, action: RegisterAction): RegisterState => {
        switch (action.type) {
            case 'SET_CLIENT':
                return { ...state, client: { ...state.client, ...action.payload } };
            case 'SET_COMPANY':
                return { ...state, company: { ...state.company, ...action.payload } };
            case 'SET_EQUIPMENT':
                return { ...state, equipment: { ...state.equipment, ...action.payload } };
            case 'SET_INSPECTOR':
                return { ...state, inspector: { ...state.inspector, ...action.payload } };
            case 'SET_PLANT':
                return { ...state, plant: { ...state.plant, ...action.payload } };
            case 'SET_PROCESS':
                return { ...state, process: { ...state.process, ...action.payload } };
            // Adicione lógica para outras ações aqui
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(registerReducer, initialState);

    const saveImageToLocalStorage = (key: string, imageFile: File) => {
        const reader = new FileReader();
        reader.onloadend = function () {
            const base64String: string = reader.result as string;
            localStorage.setItem(key, base64String);
            // Atualize o estado com a URL da imagem correspondente
            const actionType = `SET_${key.toUpperCase()}`;
            dispatch({ type: actionType as any, payload: { [`${key}Image`]: base64String } });
        };
        reader.readAsDataURL(imageFile);
    };

    const loadImageFromLocalStorage = (key: string) => {
        const savedImage = localStorage.getItem(key);
        if (savedImage) {
            // Atualize o estado com a URL da imagem carregada
            const actionType = `SET_${key.toUpperCase()}`;
            dispatch({ type: actionType as any, payload: { [`${key}Image`]: savedImage } });
        }
    };

    const value = {
        state,
        dispatch,
        saveImageToLocalStorage,
        loadImageFromLocalStorage
    };

    return <RegisterContext.Provider value={value}>{children}</RegisterContext.Provider>;
};

// Criar um hook personalizado para usar o contexto Register

const useRegister = () => {
    const context = useContext(RegisterContext);

    if (!context) {
        throw new Error('useRegister must be used within a RegisterProvider');
    }

    return context;
};

export { RegisterProvider, useRegister};



///=--------------------------------------------------------------------------------------