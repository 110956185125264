const translations: any = {
    password: {
        PT: 'Senha',
        US: 'Password',
        ES: 'Contraseña'
    },

    forgot: {
        PT: 'Esqueci a senha',
        US: 'Forgot my password',
        ES: 'Olvidé mi contraseña'
    },

    enter: {
        PT: 'Entrar',
        US: 'Login',
        ES: 'Iniciar sesión'
    },

    type: {
        PT: 'digite...',
        US: 'type here...',
        ES: 'digite...'
    },

    dbError: {
        PT: 'erro ao se comunicar com o banco de dados',
        US: 'fail to connect with data base',
        ES: ''
    },

    confirmationMail: {
        PT: 'Email de Confirmação',
        US: 'Email Confirmation',
        ES: ''
    },

    send: {
        PT: 'Enviar',
        US: 'Send',
        ES: ''
    },

    confirmationCode: {
        PT: 'Código de Confirmação',
        US: 'Confirmation Code',
        ES: ''
    },

    confirm: {
        PT: 'Confirmar',
        US: 'Confirm',
        ES: ''
    },

    newPassword: {
        PT: 'Nova Senha',
        US: 'New Password',
        ES: ''
    },

    repeatNewPassword: {
        PT: 'Repetir Nova Senha',
        US: 'Repeat New Password',
        ES: ''
    },

    incompleteData: {
        PT: 'dados não preenchidos',
        US: 'incomplete data',
        ES: ''
    },

    userNotFound: {
        PT: 'usuário não encontrado',
        US: 'user not found',
        ES: ''
    },

    incorrectPassword: {
        PT: 'Senha Incorreta',
        US: 'Incorrect Password',
        ES: ''
    },

    accessDenied: {
        PT: 'acesso negado',
        US: 'access denied',
        ES: ''
    },

    confirmationPassword: {
        PT: 'Senha de Confirmação',
        US: 'Password Confirmation',
        ES: ''
    },

    codeConfirmation: {
        PT: 'o código de confirmação é ',
        US: 'the confirmation code is ',
        ES: ''
    },

    min: {
        PT: 'confirme o email, o código expira em 5 min',
        US: 'confirm your email, the code expires in 5 min',
        ES: ''
    },

    timeout: {
        PT: 'o tempo para confirmação do código expirou... tente novamente',
        US: 'confirmation time expired... try again',
        ES: ''
    },

    invalidCode: {
        PT: 'Código Inválido',
        US: 'Invalid Code',
        ES: ''
    },

    shortPassword: {
        PT: 'Senha muito curta',
        US: 'Password too short',
        ES: ''
    },

    equalPass: {
        PT: 'senhas não são iguais',
        US: 'passwords does not match',
        ES: '' 
    }
}

export default translations;
