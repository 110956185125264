const lang: any = {
    registerCompany: {
        PT: 'Cadastrar Nova Empresa',
        US: 'Register New Company',
        ES: 'Registrar Nueva Empresa'
    },

    editCompany: {
        PT: 'Editar Empresa',
        US: 'Edit Company',
        ES: 'Editar Empresa'
    },

    companyInfo: {
        PT: 'INFORMAÇÕES DA EMPRESA',
        US: 'COMPANY INFO',
        ES: 'INFORMACIÓN DE LA EMPRESA'
    },

    name: {
        PT: 'Nome',
        US: 'Name',
        ES: 'Nombre'
    },

    occupationArea: {
        PT: 'Área de Atuação',
        US: 'Occupation Area',
        ES: 'Área de Actuación'
    },

    description: {
        PT: 'Descrição',
        US: 'Description',
        ES: 'Descripción'
    },

    companyLogo: {
        PT: 'Logo da Empresa',
        US: 'Company Logo',
        ES: 'Logo de la Empresa'
    },

    register: {
        PT: 'Cadastrar',
        US: 'Register',
        ES: 'Registrar'
    },

    edit: {
        PT: 'Editar',
        US: 'Edit',
        ES: 'Editar'
    },

    addNew: {
        PT: 'Adicionar Novo',
        US: 'Add New',
        ES: 'Agregar Nuevo'
    },

    alreadyRegistered: {
        PT: 'Empresa já cadastrada',
        US: 'Company Already Registered',
        ES: 'Empresa Ya Registrada'
    },

    addError: {
        PT: 'Erro ao adicionar',
        US: 'Error on add',
        ES: 'Error al agregar'
    },

    invalidCNPJ: {
        PT: "CNPJ Inválido!",
        US: "Invalid CNPJ!",
        ES: "¡CNPJ Inválido!"
    },

    companyRegistered: {
        PT: "Empresa Cadastrada",
        US: "Company Registered",
        ES: "Empresa Registrada"
    },
    
    wantToRegisterPlant: {
        PT: "Deseja Cadastrar uma Planta?",
        US: "Do You Want to Register a Plant?",
        ES: "¿Desea Registrar una Planta?"
    },
    yes: {
        PT: "Sim",
        US: "Yes",
        ES: "Sí"
    },
    
    no: {
        PT: "Não",
        US: "No",
        ES: "No"
    },
    
}

export default lang;
