import React, { createContext, useState, useContext, ReactNode } from 'react';
import { getStoredJson } from '../utils';

const GlobalContext = createContext<GlobalContextProps | undefined>(undefined);

export type RequestInspection = {
    id: number;
    inspector: string;
    company: string;
    plant: string;
    process: string;
    equipment: string;
    deadline: Date;

}

export type GlobalContextProps = {
    requestOptions: RequestInspection;
    setRequestOptions: React.Dispatch<React.SetStateAction<RequestInspection>>;
    globalOptions: any;
    setGlobalOptions: React.Dispatch<React.SetStateAction<any>>;
    currentId: number;
    setCurrentID: React.Dispatch<React.SetStateAction<number>>;
    genValue: any;
    setGenValue: React.Dispatch<React.SetStateAction<any>>;
}

type GlobalProviderProps = {
    children: ReactNode;
}

export function useGlobalContext() {
  const context = useContext(GlobalContext);
  if (!context) {
      throw new Error("useGlobal deve ser usado dentro de um ThemeProvider");
  }
  return context;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [globalOptions, setGlobalOptions] = useState({
    activeButton: 'Inspector',
    logged: false,
    language: 'US'
  });
  
  const [requestOptions, setRequestOptions] = useState<RequestInspection>({
    id: 0,
    inspector: '',
    company: '',
    plant: '',
    process: '',
    equipment: '',
    deadline: new Date() as Date,
  });

  const [currentId, setCurrentID] = useState<number>(0); // ou qualquer número que faça sentido para o contexto inicial
  const [genValue, setGenValue] = useState({
    plantValue: 'Planta',
    processValue: 'Processo',
    companyValue: '',
  });
  return (
      <GlobalContext.Provider value={{ requestOptions, setRequestOptions, globalOptions, setGlobalOptions, currentId, setCurrentID, genValue, setGenValue }}>
          {children}
      </GlobalContext.Provider>
  );
}


/*
PARA USAR:
import {useGlobalContext} from ...
const { globalOptions, setGlobalOptions } = useGlobalContext();
*/