const lang: any = {
    typeHere: {
        PT: 'Digite aqui',
        US: 'Type here',
        ES: 'Escriba aquí'
    },

    map: {
        PT: 'Mapa',
        US: 'Map',
        ES: 'Mapa'
    },

    of: {
        PT: 'de',
        US: 'of',
        ES: 'de'
    },

    inspected: {
        PT: 'inspecionados',
        US: 'inspected',
        ES: 'inspeccionados'
    },

    noFound: {
        PT: 'Nenhum dado encontrado',
        US: 'No data found',
        ES: 'No se encontraron datos'
    }
}

export default lang;
