import { useState } from 'react';
import './styles.css';

import flag from '../../assets/img/img_flag.png';
import flag_us from '../../assets/img/flag_us.png';
import flag_es from '../../assets/img/flag_esp.png';
import CustomModal from '../Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { deleteJsonData, getStoredJson, saveJsonData, useInitialCheck } from '../../utils';
import { useGlobalContext } from '../../contexts/GlobalContext';

type FlagSelectorProps = {
    onlyLang?: boolean;
}

function FlagSelector({ onlyLang }: FlagSelectorProps) {
    const user = getStoredJson('user', {})
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFlag, setSelectedFlag] = useState(user.lang ? user.lang : 'US');
    const [modalOpen, setModalOpen] = useState(false)

    const navigate = useNavigate()  
    const {globalOptions, setGlobalOptions} = useGlobalContext()

    useInitialCheck(handleFlagChange, [selectedFlag])

    const flags: any = {PT: flag, US: flag_us, ES: flag_es}
    const [targetFlags, setTargetFlags] = useState<any>(flags)

    function handleFlagChange(){
        setGlobalOptions({...globalOptions, language: selectedFlag})
        saveJsonData('user', {...getStoredJson('user', {}), lang: selectedFlag})

        if(selectedFlag == 'PT')
            return setTargetFlags({US: flag_us, ES: flag_es})

        if(selectedFlag == 'US')
            return setTargetFlags({PT: flag, ES: flag_es})

        if(selectedFlag == 'ES')
            return setTargetFlags({PT: flag, US: flag_us})

    }
    
    function handleFlagClick(countryCode: string){
        setSelectedFlag(countryCode);
        setIsOpen(false);
    };

    function handleLogout(){
        deleteJsonData('user')
        navigate('/')
    }
    
    return (
        <div>
            {/* <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} className='PageModal'>
                <div style={{ display: 'flex', width: '80%', padding: '1.5rem' }}>
                    <p className='whiteText' style={{ fontSize: '1.1rem', textAlign: 'center' }}>
                        Funcionalidades de Linguagem ainda não implementadas
                    </p>
                </div>

                <div onClick={() => setModalOpen(false)} style={{ cursor: 'pointer' }}>
                    <p style={{ color: 'white' }}>Fechar</p>
                </div>
            </CustomModal>   */}

            <div 
                className="flag-selector"
                style={(onlyLang) ? { width: 25 } : {}}
            >
                {/* {
                    (!onlyLang || onlyLang === undefined) &&
                    <div 
                        onClick={() => setModalOpen(true)} 
                        className='custom-button-question-container'
                        style={{ marginRight: 10 }}
                    >
                        <FontAwesomeIcon className='signOutContainer' style={{ height: '80%' }} icon={faQuestion} />
                    </div>

                } */}

                <div 
                    className='flagContainer'
                    style={(!onlyLang || onlyLang === undefined) ? { width: '30%' } : {}}
                >
                    <img 
                        src={flags[selectedFlag]} 
                        alt={selectedFlag}
                        onClick={() => setIsOpen(!isOpen)}
                        className='flagImg'
                    />
                    
                    {isOpen && (
                        <div className="flag-dropdown">
                            {Object.keys(targetFlags).map(countryCode => (
                                <img 
                                    key={countryCode}
                                    src={flags[countryCode]}
                                    alt={countryCode}
                                    onClick={() => handleFlagClick(countryCode)}
                                    style={{height: '1rem'}}
                                />
                            ))}
                        </div>
                    )}
                </div>

                {
                    (!onlyLang || onlyLang === undefined) && 
                    <div 
                        onClick={() => handleLogout()} 
                        className='custom-button-question-container'
                        style={{ marginLeft: 10 }}
                    >
                        <FontAwesomeIcon className='signOutContainer' icon={faSignOut}/>
                    </div>
                }
            </div>
        </div>
    );
}

export default FlagSelector;
