import './styles.css'

export default function AsyncButton({active=false, text='', className='', onClick=() => null, loading=null}: any){
    const buttonEnabled: any = {
        opacity: 1,
        cursor: 'pointer',
    }

    const buttonDisabled: any = {
        opacity: .7,
        cursor: 'not-allowed'
    }

    const buttonStyle: any = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        ...(active ? buttonDisabled : buttonEnabled),
    }
    
    if(loading == null)
        return (
            <div 
                className={className} 
                style={buttonStyle}
                onClick={active ? () => null : onClick}
                >

                {text}
            </div>
        )

    return (
        <div 
            className={className} 
            style={buttonStyle}
            onClick={active ? () => null : onClick}
            >

            {loading ? <div className='loadingCircle'/> : text}
        </div>
    )
}