import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChalkboardUser, faAddressCard, faMap, faChevronRight, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

import logoImage from '../../assets/img/logo.png';
import { getStoredJson, saveJsonData } from '../../utils';
import { useGlobalContext } from '../../contexts/GlobalContext';
import lang from './lang';

const Sidebar = ({activePage, history, customStyle }: any) => {
  const [paginaAtual, setPaginaAtual] = useState(activePage);
  const [cadastroAtivo, setCadastroAtivo] = useState(false);
  const [gestaoAtivo, setGestaoAtivo] = useState(false);
  const [allowed, setAllowed] = useState(true);
  const navigate = useNavigate();
  const {globalOptions, setGlobalOptions} = useGlobalContext()
  const currLang = globalOptions.language
  const user = getStoredJson('user')
  const permissions = JSON.parse(user.permission)

  
  useEffect(() => {
    if(
        !permissions?.ger_plants.includes('c')     && !permissions?.ger_companies.includes('c')  &&
        !permissions?.ger_processes.includes('c')  && !permissions?.ger_equipments.includes('c') &&
        !permissions?.ger_inspectors.includes('c') && !permissions?.ger_clients.includes('c')
      )

      setAllowed(false)
  }, [paginaAtual])

  const handleTrocarPagina = (novaPagina: string) => {
    setPaginaAtual(novaPagina);
  };

  const handleActiveState = (estado: string) => {
    navigate(`/Managment`);
  };

  function canRegisterCompany(){
    if(user.company == '123456789/0001')
      return true

    if(permissions.ger_companies.includes('c'))
      return true

    return false
  }

  return (
    <div className="sidebar" style={{...customStyle}}>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <img className="sideLogo" src={logoImage} alt="Imagem do Topo" />
      </div>

      <div style={{padding: '1rem'}}/>

      <div style={{display: 'flex', width: '95%', alignItems: 'center', padding: '10px'}}>
        <FontAwesomeIcon icon={faUserCircle} style={{fontSize: '12pt'}}/>

        <div style={{padding: '3%'}}/>

        <p>{getStoredJson('user') ? getStoredJson('user').name.split(' ')[0] : 'Convidado'}</p>
      </div>

      <Link to="/Home" className="linkStyle">
        <button 
          className={`sideButtonInitial ${paginaAtual === 'Home' ? 'active' : ''}`}
          onClick={() => {
            handleTrocarPagina('Home')
            setCadastroAtivo(false);
            setGestaoAtivo(false);
          }}
        >
          <FontAwesomeIcon icon={faHome} />
          <span className="button-text">{lang.initialPage[currLang]}</span>
        </button> 
      </Link>
      <button 
        className={`${allowed == true ? 'sideButtonRegister' : 'sideButtonRegister_disalowed'} ${paginaAtual === 'Register' && cadastroAtivo ? 'active' : ''} ${paginaAtual === 'Register' && !cadastroAtivo ? 'drop-inactive' : ''}`}
        disabled={!allowed}
        onClick={() => {
          if (allowed) {
            handleTrocarPagina('Register');
            setCadastroAtivo(!cadastroAtivo);
            setGestaoAtivo(false);
          }
        }}
      >
        <FontAwesomeIcon icon={faAddressCard} />
        <span className="button-text">{lang.register[currLang]}</span>
      </button> 

      {cadastroAtivo && (
      <div className="subitens">
        <div className="subitem">
          <span className="subname">{lang.registerCompany[currLang]}</span>
          <button
            className={canRegisterCompany() ? 'sidearrow' : 'item_disabled'}
            disabled={!canRegisterCompany()}
            onClick={canRegisterCompany() ? () => navigate('/Company') : () => null}
          >
              
              <FontAwesomeIcon icon={faChevronRight} className="iconarrow"/>
            </button>
        </div>
        
        <hr className="sideline" />

        <div className="subitem">
          <span className="subname">{lang.registerPlant[currLang]}</span>
            <button
              className={`${permissions.ger_plants.includes('c') ? 'sidearrow' : 'item_disabled'}`}
              disabled={!permissions.ger_plants.includes('c')}
              onClick={() => {
                if (permissions.ger_plants.includes('c')) {
                  navigate(`/Plant`);
                }
            }}>
            <FontAwesomeIcon icon={faChevronRight} className="iconarrow" />
            </button>
        </div>

        <hr className="sideline" />

        <div className="subitem">
          <span className="subname">{lang.registerProcess[currLang]}</span>
            <button
                className={`${permissions.ger_processes.includes('c') ? 'sidearrow' : 'item_disabled'}`}
                disabled={!permissions.ger_processes.includes('c')}
                onClick={() => {
                  if (permissions.ger_processes.includes('c')) {
                    navigate(`/Process`);
                  }
            }}>
              <FontAwesomeIcon icon={faChevronRight} className="iconarrow"/>
            </button>
        </div>
        
        <hr className="sideline" />

        <div className="subitem">
          <span className="subname">{lang.registerEquipment[currLang]}</span>
            <button
                className={`${permissions.ger_equipments.includes('c') ? 'sidearrow' : 'item_disabled'}`}
                disabled={!permissions.ger_equipments.includes('c')}
                onClick={() => {
                  if (permissions.ger_equipments.includes('c')) {
                    navigate(`/Equipment`);
                  }
            }}>
            <FontAwesomeIcon icon={faChevronRight} className="iconarrow" />
            </button>
        </div>

        <hr className="sideline" />

        <div className="subitem">
          <span className="subname">{lang.registerInspector[currLang]}</span>
            <button
                className={`${permissions.ger_inspectors.includes('c') ? 'sidearrow' : 'item_disabled'}`}
                disabled={!permissions.ger_inspectors.includes('c')}
                onClick={() => {
                  if (permissions.ger_inspectors.includes('c')) {
                    navigate(`/Inspector`);
                  }
            }}>
            <FontAwesomeIcon icon={faChevronRight} className="iconarrow" />
            </button>
        </div>

        <hr className="sideline" />

        <div className="subitem">
          <span className="subname">{lang.registerClient[currLang]}</span>
            <button
                className={`${permissions.ger_clients.includes('c') ? 'sidearrow' : 'item_disabled'}`}
                disabled={!permissions.ger_clients.includes('c')}
                onClick={() => {
                  if (permissions.ger_clients.includes('c')) {
                    navigate(`/Client`);
                  }
            }}>
            <FontAwesomeIcon icon={faChevronRight} className="iconarrow" />
            </button>
        </div>
      </div>
      )}

      <Link to="/Map" className="linkStyle">
        <button 
          className={`sideButtonInitial ${paginaAtual === 'Search' ? 'active' : ''}`}
          onClick={() => {
            handleTrocarPagina('Search')
            setCadastroAtivo(false);
            setGestaoAtivo(false);
          }}
          
        >
          <FontAwesomeIcon icon={faMap} />
          <span className="button-text">{lang.explore[currLang]}</span>
        </button> 
      </Link>

      <button 
        className={`sideButtonManagement ${paginaAtual === 'Management' && gestaoAtivo ? 'active' : ''} ${paginaAtual === 'Management' && !gestaoAtivo ? 'drop-inactive2' : ''}`}
        onClick={() => {
          handleTrocarPagina('Management')
          setGestaoAtivo(!gestaoAtivo);
          setCadastroAtivo(false);
        }}
      >
        <FontAwesomeIcon icon={faChalkboardUser} />
        <span className="button-text">{lang.management[currLang]}</span>
      </button> 

      {gestaoAtivo && (
      <div className="subitens">
  
        <div className="subitem">
          <span className="subname">{lang.managementCompanies[currLang]}</span>
            <button
                className={'sidearrow'}
                disabled={false}
                onClick={() => {
                  setGlobalOptions({...globalOptions, activeButton: 'Company'}); 
                  handleActiveState('Company')
                }}
              >
            
              <FontAwesomeIcon icon={faChevronRight} className="iconarrow" />
            </button>
        </div>

        <hr className="sideline" />

        <div className="subitem">
          <span className="subname">{lang.managementInspectors[currLang]}</span>
          <button className="sidearrow" onClick={() => {setGlobalOptions({...globalOptions, activeButton: 'Inspector'}); handleActiveState('Inspector')}}>
          <FontAwesomeIcon icon={faChevronRight} className="iconarrow" />
          </button>
        </div>

        <hr className="sideline" />

        <div className="subitem">
          <span className="subname">{lang.managementAcesses[currLang]}</span>
          <button className="sidearrow" onClick={() => {setGlobalOptions({...globalOptions, activeButton: 'Client'}); handleActiveState('Client')}}>
          <FontAwesomeIcon icon={faChevronRight} className="iconarrow" />
          </button>
        </div>

        <hr className="sideline" />

        <div className="subitem">
          <span 
            className="subname"
          >
              {lang.reports[currLang]}
          </span>
            
          <button 
            className="sidearrow" 
            onClick={() => {
              navigate('/Corrosion');
          }}>

          <FontAwesomeIcon icon={faChevronRight} className="iconarrow" />
          </button>
        </div>

      </div>
      )}

      <div className="sideBarVersion">v1.4.0</div>

    </div>
  );
};

export default Sidebar;