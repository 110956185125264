const lang: any = {
    initialPage: {
        PT: 'Início',
        US: 'Home',
        ES: 'Início'
    },

    register: {
        PT: 'Cadastro',
        US: 'Register',
        ES: 'Registro'
    },

    registerCompany: {
        PT: 'Empresa',
        US: 'Company',
        ES: 'Empresa'
    },

    registerPlant: {
        PT: 'Planta',
        US: 'Plant',
        ES: 'Planta'
    },

    registerProcess: {
        PT: 'Processo',
        US: 'Process',
        ES: 'Proceso'
    },

    registerEquipment: {
        PT: 'Equipamento',
        US: 'Equipment',
        ES: 'Equipo',
    },

    registerInspector: {
        PT: 'Inspetor',
        US: 'Inspector',
        ES: 'Inspector'
    },

    registerClient: {
        PT: 'Cliente',
        US: 'Client',
        ES: 'Cliente'
    },

    explore: {
        PT: 'Explorar',
        US: 'Explore',
        ES: 'Explorar'
    },

    management: {
        PT: 'Gestão',
        US: 'Management',
        ES: 'Gestión'
    },

    managementCompanies: {
        PT: 'Empresas',
        US: 'Companies',
        ES: 'Empresas',
    },

    managementInspectors: {
        PT: 'Inspetores',
        US: 'Inspectors',
        ES: 'Inspectores'
    },

    managementAcesses: {
        PT: 'Acessos',
        US: 'Acessess',
        ES: 'Accesos'
    },

    reports: {
        PT: 'Relatórios',
        US: 'Reports',
        ES: 'Relatórios'
    }
}

export default lang;
