import FlagSelector from "../../../components/FlagSelector";
import Sidebar from "../../../components/Sidebar";
import { faAngleLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getStoredJson, saveJsonData, useInitialCheck } from "../../../utils";
import { editDatabaseByCondition, getDataBaseCondition, getImagePaths } from "../../../utils/database";
import { formatTimestamp } from "../../../components/DatePicker";
import logoImage from '../../../assets/img/logo.png';
import ExcelJS, { BorderStyle } from 'exceljs';
import { useGlobalContext } from "../../../contexts/GlobalContext";
import lang from "./lang";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './styles.css'
import { inspect } from "util";

function renderCameraIcon(width: string, height: string, img: any = ''){
    if(!img || img == '')
        return (
            <div className='cameraContainer' style={{width: width, height: height}}>
                <FontAwesomeIcon icon={faCamera} className="cameraIcon"/>
            </div>
        )
    
    return (<img className='cameraContainer' style={{width: width, height: height}} src={img} alt=""/>)
}

export function RegisterPage(){
    const [selectedOption, setSelectedOption] = useState('photos')

    const stored: any   = getStoredJson('pendingInspection')
    const relatory: any = stored.relatory ? JSON.parse(stored.relatory) : {}
    const isEdit: boolean = Object.keys(relatory).length == 0
    const inspection: any = renderData(stored, relatory)
    
    const [equipment, setEquipment] = useState<string>('')
    const [company, setCompany] = useState<string>('')
    const [companyImg, setCompanyImg] = useState<string>('')
    const [plant, setPlant] = useState<string>('')
    const [process, setProcess] = useState<string>('')
    const [inspector, setInspector] = useState<string>('')
    const [images, setImages] = useState<any>([])

    const {globalOptions, setGlobalOptions} = useGlobalContext()
    const currLang = globalOptions.language
    const navigate = useNavigate()
    useInitialCheck(importInfo, [inspection])
    useInitialCheck(importImages)
    
    async function importInfo(){
        let response
        let inspection = await getDataBaseCondition('inspections', 'id', stored.inspection_id)

        response = await getDataBaseCondition('companies', 'id', stored.company_id)
        setCompany(response[0].name)
        setCompanyImg(response[0].companyImage)

        if(inspection.length){
            response = await getDataBaseCondition('plants', 'plant_id', inspection[0].plant)
            setPlant(response[0].name)

            response = await getDataBaseCondition('processes', 'process_id', inspection[0].process)
            setProcess(response[0].name)

            response = await getDataBaseCondition('equipments', 'equipment_id', inspection[0].equipment)
            setEquipment(response[0].name)

            setInspector(inspection[0].inspector)
        }
        
    }

    async function importImages(){
        if(!stored.id)
            return null
        
        const response = await getImagePaths(stored.img_path)
        setImages(response)
    }

    function renderData(storedData: any, relatoryData: any){
        //alert(JSON.stringify(storedData))
        return {
            created: storedData.server_created_at   ? storedData.server_created_at : '???',
            company: storedData.company     ? storedData.company  : '???',
            plant: storedData.plant         ? storedData.plant    : '???',
            process: storedData.process     ? storedData.process  : '???',
            inspector: storedData.inspector ? storedData.inspector : '???',
            equipment: storedData.equipment ? storedData.equipment: '???',
        }
    }
    

    function renderOption(key: string, value: any, line: boolean=true, opacity: number=0.5){
        return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            {!line && (<div style={{padding: '.2rem'}}/>)}
            <div style={{display: 'flex', justifyContent: 'space-between', width: '98%'}}>
                <p style={{fontSize: '1.1rem', opacity: opacity, color: 'white'}}>{key}</p>

                <p style={{fontSize: '1.1rem', opacity: opacity, color: 'white'}}>{value}</p>
            </div>
            
            <div style={{padding: '.2rem'}}/>
            
            {line && (<div className="line" style={{opacity: opacity}}/>)}
            {line && (<div style={{padding: '.2rem'}}/>)}
        </div>
        )
    }

    function renderPhotos(){
        let block = []
        //alert(images)
        for(let x=0; x<images.length; x = x + 4)
            block.push(
                <div key={x} style={{display: 'flex', marginBottom: '1rem'}}>
                    {(x + 0 < images.length) &&
                        <div className="photoContainer">
                            {renderCameraIcon('100%', '10rem', images[x])}
                            
                            <p className="photoTitle">{x}</p>
                        </div>
                    }

                    <div style={{width: '6.666%'}}/>

                    {(x + 1 < images.length) &&
                        <div className="photoContainer">
                            {renderCameraIcon('100%', '10rem', images[x+1])}
                            
                            <p className="photoTitle">{x+1}</p>
                        </div>
                    }

                    <div style={{width: '6.666%'}}/>

                    {(x + 2 < images.length) &&
                        <div className="photoContainer">
                            {renderCameraIcon('100%', '10rem', images[x+2])}
                            
                            <p className="photoTitle">{x+2}</p>
                        </div>
                    }

                    <div style={{width: '6.666%'}}/>

                    {(x + 3 < images.length) &&
                        <div className="photoContainer">
                            {renderCameraIcon('100%', '10rem', images[x+3])}
                            
                            <p className="photoTitle">{x+3}</p>
                        </div>
                    }
                </div>
            )
        
        return block
    }

    function renderActive(){
        if (selectedOption === 'generalInformation')
            return (
                <div>
                    {renderOption(lang.createdOn[currLang], formatTimestamp(inspection.created))}
                    {renderOption(lang.company[currLang], company)}
                    {renderOption(lang.plant[currLang], plant)}
                    {renderOption(lang.process[currLang], process)}
                    {renderOption(lang.equipment[currLang], equipment)}
                    {renderOption(lang.inspectorName[currLang], inspector)}
                </div>
            )
            
            if (selectedOption === 'photos')
                return renderPhotos();

        return (<div/>)
    }

    function renderTitleOptions(){
        return (
            <div className="ExploreRow">
                <div/>
                <p 
                    className="optionText" 
                    onClick={() => setSelectedOption('photos')}
                    style={{color: selectedOption == 'photos' ? '#f36934' : 'white'}}
                >
                    {lang.photos[currLang]}
                </p>

                <div/>

                <p 
                    className="optionText" 
                    onClick={() => setSelectedOption('generalInformation')}
                    style={{color: selectedOption == 'generalInformation' ? '#f36934' : 'white'}}
                >
                    Informações Gerais
                </p>

                <div/>

            </div>
        )
    }

    async function handleEdit(){
        saveJsonData('edit', {
            ...stored,
            userName: stored.inspector
        })

        return navigate('/Request?edit')
    }


    return (
    <div className="MainContainer">
        <FlagSelector/>
        <Sidebar activePage='Explore'/>
        
        <div className='SideBarCompensation'/>

        <div className="CentralBlock">
            <div className="ExploreInfoContainer">
                <div className="ExploreRow">
                    <div className="arrow_Explorar" onClick={() => navigate(-1) }>
                        <FontAwesomeIcon icon={faAngleLeft} className="backIcon_Register"/>
                    </div>

                    <p className="dateText">{stored.title}</p>

                    <div style={{flex: 1}}/>
                    </div>

                {renderTitleOptions()}

                <div className="line"/>
                <div style={{padding: '.5rem'}}/>

                <div className="scrollViewExplore">
                    {renderActive()}
                </div>
            </div>
        </div>
    </div>
    )
}